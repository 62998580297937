<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="3"
      >
        <h6 class="text-primary">
          Diurno / Nocturno
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.tipoFormulario.nombre.toUpperCase()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="3"
      >
        <h6 class="text-primary">
          Identificación / Poste
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.posteReferencia.toUpperCase()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="3"
      >
        <h6 class="text-primary">
          Zona
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.zona"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="3"
      >
        <h6 class="text-primary">
          Grupo
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.grupo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="3"
      >
        <h6 class="text-primary">
          Estado
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.estado"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="9"
      >
        <h6 class="text-primary">
          Categoría
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.categoria ? conservacion.categoria.nombre : ''"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <h3 class="text-primary">
      Trabajo Realizado
      <hr>
    </h3>
    <b-row>
      <b-col
        cols="12"
      >
        <h6 class="text-primary">
          Trabajo(s) Realizado(s)
        </h6>
        <b-form-group>
          <b-form-input
            :value="getListadoTrabajos(conservacion.trabajosRealizados)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="conservacion.trabajosRealizados.filter(i => i.id === 8).length > 0"
        cols="12"
      >
        <h6 class="text-primary">
          Metros de Poda
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.trabajosRealizados.filter(i => i.id === 8)[0].trabajo"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="conservacion.trabajosRealizados.filter(i => i.id === 2).length > 0">
      <b-col
        cols="12"
        sm="4"
      >
        <h6 class="text-primary">
          Código Anterior
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.trabajosRealizados.filter(i => i.id === 2)[0].trabajo.codigoAnterior"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <h6 class="text-primary">
          Código Nuevo
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.trabajosRealizados.filter(i => i.id === 2)[0].trabajo.codigoNuevo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <h6 class="text-primary">
          Potencia
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.trabajosRealizados.filter(i => i.id === 2)[0].trabajo.potencia"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="conservacion.trabajosRealizados.filter(i => i.id === 3).length > 0">
      <b-col
        cols="12"
        sm="6"
      >
        <h6 class="text-primary">
          Brazo Retirado
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.trabajosRealizados.filter(i => i.id === 3)[0].trabajo.brazoRetirado.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <h6 class="text-primary">
          Brazo Instalado
        </h6>
        <b-form-group>
          <b-form-input
            :value="conservacion.trabajosRealizados.filter(i => i.id === 3)[0].trabajo.brazoInstalado.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Observaciones
        </h6>
        <b-form-group>
          <b-form-textarea
            v-model="conservacion.observaciones"
            rows="2"
            no-resize
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h3 class="text-primary">
          Seguimiento
        </h3>
        <vue-good-table
          :columns="columns"
          :rows="conservacion.seguimiento"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <b-button
                v-if="props.row.url"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                @click="mostrarImagen(props.row)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <p v-if="props.row.comentarios">{{ props.row.comentarios }}</p>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Detalle de la Imagen"
    >
      <div>
        <center>
          <b-img
            :src="urlImagen"
            fluid
            alt="Imagen Cargada')"
          />
        </center>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormInput, BFormGroup, BModal, BButton, VBModal, BImg, BFormTextarea,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { getListadoTrabajos } from '@/utils/mantenimiento/conservacion/conservacionUtils'

export default {
  components: {
    BFormTextarea,
    BImg,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BModal,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    conservacion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlImagen: '',
      verDialogoDocumento: false,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      rows: [
        {
          id: 1, tipo: 'Panoramica', fechaHora: '10/02/2023, 12:30:50',
        },
        {
          id: 2, tipo: 'Panoramica', fechaHora: '10/02/2023, 12:30:50',
        },
      ],
    }
  },
  methods: {
    getListadoTrabajos,
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
  },
}
</script>
