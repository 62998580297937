<template>
  <div>
    <b-modal
      v-model="verDialogoAnulacion"
      ok-only
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-variant="primary"
      ok-title="Cancelar"
      modal-class="modal-primary"
      centered
      title="Anular Ticket"
      @ok="limpiarTexto"
      @close="limpiarTexto"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <label>Ingrese un comentario:</label>
            <b-form-textarea
              v-model="razon"
              row="2"
            />
          </b-col>
          <b-col cols="12">
            <br>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-danger"
              block
              @click="anularTicket(falla)"
            >
              Anular Ticket
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BRow, BCol, BFormTextarea, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { updateCreateFalla, getFalla } from '@/utils/mantenimiento/fallasBt/fallasBtUtils'
import { updateCreateConservacion } from '@/utils/mantenimiento/conservacion/conservacionUtils'

export default {
  name: 'ModalBotonAnulacion',
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    origen: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verDialogoAnulacion: false,
      razon: '',
      falla: null,
    }
  },
  async created() {
    this.fallaBt = await getFalla(this.id)
  },
  methods: {
    limpiarTexto() {
      this.razon = ''
    },
    anularTicket() {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que desea anular el ticket, está acción no es reversible!', {
          title: 'Mensaje de Confirmación',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (this.razon.trim() === '') {
              this.error('Debe ingresar un comentario!')
              return
            }
            const usuario = JSON.parse(localStorage.getItem('userData'))
            this.ticket.estado = 'ANULADA'
            this.ticket.seguimiento.push({
              tipo: 'Anulación del Ticket',
              estado: 'Anulación del Ticket',
              fechaHora: new Date(),
              comentarios: this.razon,
              usuario: {
                id: usuario.id,
                nombre: `${usuario.firstName} ${usuario.firstSurname}`,
                name: `${usuario.firstName} ${usuario.firstSurname}`,
                email: usuario.email,
                telefono: '',
              },
            })

            let resultado = null

            if (this.origen === 'FALLABT') {
              resultado = await updateCreateFalla(this.ticket, 1)
            } else if (this.origen === 'CONSERVACION') {
              resultado = await updateCreateConservacion(this.ticket, 1)
            }

            if (resultado === null) {
              this.mensajeError('Ocurrió un problema actualizando el ticket')
              return
            }
            this.success('Anulación Ticket', 'Ticket actualizado correctamente!')
            this.$emit('cerrar-dialogo')

            this.razon = ''
          }
        })
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>

</style>
